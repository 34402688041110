import { useWcag } from '../../hooks/useWcag';
import locales from '../../locales';
interface WcagInterface{
    isPopupVisible: boolean;
}
const WcagPopup = ({isPopupVisible}: WcagInterface) => {
    const {increaseFontSize, decreaseFontSize, setContrastMode} = useWcag();

    return (
        <>
            {isPopupVisible && (
                <div className="absolute left-[0px] bottom-[130px] bg-white shadow-lg p-4 rounded border z-10 w-[200px] min-h-[164px]">
                    <p className="mb-2 font-normal font-custom-sm">{locales.fontSize}</p>
                    <div className='flex row'>
                        <button
                            onClick={increaseFontSize}
                            className="px-2 py-1 bg-secondary border border-contrastBlack rounded mr-2"
                        >
                            A+
                        </button>
                        <button
                            onClick={decreaseFontSize}
                               className="px-2 py-1 bg-secondary border border-contrastBlack rounded mr-2"
                        >
                            A-
                        </button>
                    </div>
                    <p className="mb-2 font-normal font-custom-sm mt-1">{locales.contrast}</p>
                    <button
                        onClick={setContrastMode}
                        className="px-2 py-1 bg-secondary border border-contrastBlack rounded mr-2"
                    >
                        C
                    </button>
                </div>
            )}
        </>
    );
};

export default WcagPopup;