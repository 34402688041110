import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WcagProvider } from './hooks/useWcag';
import { SocketIoProvider } from './hooks/useSocketIo';
import { ChatHistoryProvider } from './hooks/useChatHistory';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChatHistoryProvider>
      <SocketIoProvider>
        <WcagProvider>
          <App />
        </WcagProvider>
      </SocketIoProvider>
    </ChatHistoryProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
