// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-box{
    box-shadow: 0px 4px 4px 0px #00000040;
}

.lottie-dots-animation{
    div{
        margin: 0 !important;
        margin-left: 30px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/chat/chat.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;AACzC;;AAEA;IACI;QACI,oBAAoB;QACpB,4BAA4B;IAChC;AACJ","sourcesContent":[".main-box{\n    box-shadow: 0px 4px 4px 0px #00000040;\n}\n\n.lottie-dots-animation{\n    div{\n        margin: 0 !important;\n        margin-left: 30px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
