import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { Chat } from '../models/chat';
import { useChatHistory } from './useChatHistory';

interface SocketIoInterface{
    threadId?: string | null;
    setThreadId?: (threadId: string) => void;
    sendMessage: (message: string) => void;
    socket?: any;
    isWaitingForResponse?: boolean;
    setIsWaitingForResponse: (isWaiting: boolean) => void;
}

interface SocketIoProviderInterface{
    children: ReactNode;
}

const defaultValue: SocketIoInterface = {
    threadId: null,
    setThreadId: (threadId: string) => {},
    sendMessage: (message: string) => {},
    socket: null,
    isWaitingForResponse: false,
    setIsWaitingForResponse: () => {}
};

const SocketIoContext = createContext(defaultValue);


export const SocketIoProvider = ({ children}: SocketIoProviderInterface) => {

    const [socket, setSocket] = useState<any>(null);
    const [threadId, setThreadId] = useState<string | null>(null)
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false)

    const {addChatResponseToHistory} = useChatHistory();
  
    useEffect(() => {
        const CHAT_API_URL = 'https://drural.test.t-ww.pl/';
        const newSocket = io(CHAT_API_URL);
        setSocket(newSocket);
  

      newSocket.on('connect', () => {
        console.log('Połączono z serwerem Socket.IO');
      });
  

      newSocket.on('disconnect', () => {
        console.log('Rozłączono z serwerem Socket.IO');
      });
  
      newSocket.on('response', (data: string) => {
        setIsWaitingForResponse(false)
        addChatResponseToHistory(data)
      })
  
      newSocket.on('on_connect', (data) => {
        const validJsonString = data.replace(/'/g, '"');
        const storedData = localStorage.getItem('druralChatHistory');
        let storedChat:Chat | null = null;
        let isThreadId:string | null = null;
        if(storedData){
            storedChat = JSON.parse(storedData);
            console.log(storedChat?.threadId)
            isThreadId = storedChat?.threadId || null;
            setThreadId(storedChat?.threadId ?? null)
        }
        
        if(!isThreadId){
            try {
                const parsedData = JSON.parse(validJsonString);
                const newThreadId: string = parsedData?.thread_id;
                setThreadId(newThreadId)
                localStorage.setItem('druralChatHistory', JSON.stringify({...storedChat, threadId: newThreadId }))
              } catch (error) {
                console.error('Błąd parsowania danych JSON:', error);
              }
        }

      });
  
      return () => {
        newSocket.disconnect();
      };
    }, []);
  
    const sendMessage = (message: string) => {
      if (socket) {
        socket.emit('message', {body: message, title: threadId}); 
      }
    };

    return (
        <SocketIoContext.Provider value={{ 
            threadId,
            setThreadId,
            sendMessage,
            socket,
            isWaitingForResponse,
            setIsWaitingForResponse
            }}>
            {children}
        </SocketIoContext.Provider>
    );
};

export const useSocketIo = () => {
    const context = useContext(SocketIoContext);
    if(!context){
        throw new Error('useSocketIo must be used within an SocketIoProvider');
    }
    return context;
}