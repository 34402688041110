import { useEffect, useState } from "react";
import locales from "../../locales"
import { Button } from "../button/button"
import { useWcag } from "../../hooks/useWcag";
import { useSocketIo } from "../../hooks/useSocketIo";
import { updateLocalStorage } from "../../helpers/localStorageHelpers";
import { useChatHistory } from "../../hooks/useChatHistory";

import writingAnimation from '../../assets/animations/avatar_02.json';

type MessageInputProps = {
  scrollToBottom: () => void;
  setCurrentAnimation: (animation: any) => void;
};

export const MessageInput: React.FC<MessageInputProps> = ({ scrollToBottom, setCurrentAnimation }) =>{

  const [inputValue, setInputValue] = useState('');

  const {isHighContrast} = useWcag();
  const {sendMessage, setIsWaitingForResponse} = useSocketIo();
  const {addMessageToHistory} = useChatHistory();

  useEffect(()=>{
    if(inputValue){
      setCurrentAnimation(writingAnimation);
    }
  },[inputValue])

  const sanitizeInput = (value: string) => {
    return value.replace(/<[^>]*>/g, '');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = sanitizeInput(event.target.value);
    setInputValue(sanitizedValue);
  };


  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (inputValue.trim() === '') {
      alert('Input cannot be empty');
    }
 
    sendMessage(inputValue);
    setIsWaitingForResponse(true);
    addMessageToHistory({role: 'user', message: inputValue})
    updateLocalStorage({message: inputValue, role: 'user'})

    setInputValue('');
    requestAnimationFrame(scrollToBottom);
  };
    return(
        <form className="flex items-center justify-center w-full space-x-2">
            <input
            className={`flex h-10 w-full rounded-md border ${isHighContrast ? 'border-contrastBlack placeholder-contrastBlack' : 'border-[#e5e7eb]'} px-3 py-2 font-custom-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2`}
            placeholder={locales.typeQuestion} 
            value={inputValue}
            onChange={handleInputChange}
            />
            <Button onClick={handleSubmit} disabled={!inputValue}/>
        </form>
    )
}