import { useWcag } from "../../hooks/useWcag";
import locales from "../../locales"

interface ButtonProps{
    disabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button = ({disabled, onClick}: ButtonProps) => {

    const {isHighContrast} = useWcag();

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`${isHighContrast ? 'disabled:bg-contrastWhite disabled:text-contrastBlack bg-contrastWhite border-contrastBlack border' : 'disabled:bg-primary'} inline-flex items-center justify-center rounded-md font-custom-base font-medium text-title disabled:text-secondary disabled:pointer-events-none disabled:bg-primary bg-icon hover:opacity-70 h-10 px-4 py-2`}>
                {locales.send}
        </button>
    )
}