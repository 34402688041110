const locales = {
    chatbot: "AI Chatbot",
    chatbotDescription: "Virtual Assistant for Oswiecim City",
    chatbotRight: "Chat. Powered by dRural. All rights reserved.",
    send: "Send",
    aiName: "AI Assistant",
    you: "You",
    typeQuestion: "Type your question here",
    fontSize: "Rozmiar czcionki:",
    contrast: "Kontrast:"
}

export default locales;