import { MessageInterface } from '../../models/message';
import { MessageInput } from '../message-input/message-input';
import { Message } from '../message/message';
import { useWcag } from '../../hooks/useWcag';
import { useChatHistory } from '../../hooks/useChatHistory';
import Lottie from 'react-lottie';
import dotsAnimation from '../../assets/animations/dots.json';
import { useSocketIo } from '../../hooks/useSocketIo';
import { useEffect, useRef } from 'react';
import './chat.css';

import waitingAnimation from '../../assets/animations/avatar_04.json';
import answerAnimation from '../../assets/animations/avatar_03.json';
import stillAnimation from '../../assets/animations/avatar_05.json';

interface ChatProps {
    setCurrentAnimation: (animation: any) => void;
}

export const Chat = ({setCurrentAnimation}: ChatProps) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dotsAnimation,
      };
    const {isHighContrast,} = useWcag();
    const {chatHistory } = useChatHistory();
    const {isWaitingForResponse} = useSocketIo();


    const renderChatHistory = () => {
        return chatHistory?.map((message: MessageInterface)=> {
            return <Message role={message.role} message={message.message}/>
        })
    }

    const scrollableChatRef = useRef<HTMLDivElement>(null);
    const scrollToElement = useRef<HTMLSpanElement>(null)

    const scrollToBottom = () => {
      if (scrollToElement.current) {
        scrollToElement.current.scrollIntoView({behavior: 'smooth'})
      }
    };

    useEffect(()=>{
        requestAnimationFrame(scrollToBottom);
    },[])

    useEffect(()=>{
        if(isWaitingForResponse){
            setCurrentAnimation(waitingAnimation)
        }else if(chatHistory && chatHistory?.length > 0){
            if(chatHistory[chatHistory.length-1].role === 'chat'){
                setCurrentAnimation(answerAnimation)
                setTimeout(()=>{
                    setCurrentAnimation(stillAnimation)
                },10000)
            }
        }
    },[isWaitingForResponse])

    return(
        <>
            <div className={`bg-white py-10 px-12 rounded-xl border w-full h-full ${isHighContrast ? 'border-contrastBlack' : 'main-box border-[#e5e7eb]'}`}>
                <div className="h-[90%] w-full overflow-y-auto" ref={scrollableChatRef}>
                {renderChatHistory()}
                {isWaitingForResponse && 
                    <div className='w-full flex lottie-dots-animation'>
                        <Lottie options={defaultOptions} height={100} width={100} ariaRole='div'/>
                    </div>
                }
                <span ref={scrollToElement}></span>
                </div>
                <div className="flex items-center pt-0">
                <MessageInput scrollToBottom={scrollToBottom} setCurrentAnimation={setCurrentAnimation}/>
                </div>
            </div>
        </>
    )
}