import { Chat } from './components/chat/chat';
import wcagIcon from './assets/img/wcag_icon.png'
import locales from './locales';
import { useWcag } from './hooks/useWcag';
import { useState } from 'react';
import WcagPopup from './components/wcag-popup/wcag-popup';
import Lottie from 'react-lottie';
import './App.css';

import waveAnimation from './assets/animations/avatar_01.json';
import stillAnimation from './assets/animations/avatar_05.json';


function App() {

  const [currentAnimation, setCurrentAnimation] = useState<any>(waveAnimation);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: currentAnimation,
  };

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false)

  const togglePopup = () => {
    setIsPopupVisible((prevState) => !prevState);
  };

  const {isHighContrast} = useWcag();

  const onContainerClick = () => {
    setCurrentAnimation(stillAnimation)
  }

  return (
      <div className={`${isHighContrast ? 'bg-contrastWhite' : 'bg-primary '} w-full h-custom py-12 px-14 flex flex-row`} onClick={onContainerClick}>
        <div className='h-full side-bar-panel flex flex-col justify-between'>
          <div>
            <Lottie options={defaultOptions} height={100} width={100} ariaRole='img' style={isHighContrast ? { filter: 'grayscale(100%)' } : {}} />
            <h1 className={`avatar-title font-custom-xl`}>{locales.aiName}</h1>
            <p className={`${isHighContrast ? '' : 'text-subtitle'} font-normal font-custom-sm`}>{locales.chatbotDescription}</p>
          </div>
          <div className="relative inline-block">
            <WcagPopup isPopupVisible={isPopupVisible}/>
            <button
              onClick={togglePopup}
            >
              <img src={wcagIcon} alt="WCAG Icon" className={`${isHighContrast ? 'grayscale' : ''}`}/>
            </button>
            <p className={`mt-5 ${isHighContrast ? '' : 'text-rights'} text-normal font-custom-xs`}>{locales.chatbotRight}</p>
          </div>
        </div>
        <Chat setCurrentAnimation={setCurrentAnimation}/>
      </div>
  );
}

export default App;
