import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Chat } from '../models/chat';
import { MessageInterface } from '../models/message';
import { updateLocalStorage } from '../helpers/localStorageHelpers';

interface ChatHistoryInterface{
    chatHistory?: MessageInterface[];
    setChatHistory: (chat: MessageInterface[]) => void;
    addMessageToHistory: (message: MessageInterface) => void;
    addChatResponseToHistory: (data: string) => void;
}

interface ChatHistoryProviderInterface{
    children: ReactNode;
}

const defaultValue: ChatHistoryInterface = {
    chatHistory: [],
    setChatHistory: () => {},
    addMessageToHistory: () => {},
    addChatResponseToHistory: () => {'test'}
};

const ChatHistoryContext = createContext<ChatHistoryInterface>(defaultValue);


export const ChatHistoryProvider = ({ children}: ChatHistoryProviderInterface) => {
    const [chatHistory, setChatHistory] = useState<MessageInterface[]>([]);

    const getStoredChatHistory = () => {
        const storedChatHistory = localStorage.getItem('druralChatHistory');
        if(storedChatHistory){
            const parsedChatHistory: Chat = JSON.parse(storedChatHistory);
            if(parsedChatHistory.history){
                return parsedChatHistory.history;
            }
        }
        return [];
    }

    useEffect(()=>{
       setChatHistory(getStoredChatHistory());
    },[])

    const addMessageToHistory = (message: MessageInterface) =>{
        setChatHistory((prevChatHistory: MessageInterface[])=> [...prevChatHistory, message])
    }

    const addChatResponseToHistory = (data: string) => {

        const storedChatHistory = getStoredChatHistory();
        const chatLength = storedChatHistory?.length;
        const lastMessage = storedChatHistory[chatLength - 1]
        if(lastMessage?.role === "user"){
            updateLocalStorage({message: data, role: 'chat'})
            setChatHistory((prevChatHistory)=> [...prevChatHistory, {message: data, role: 'chat'}])
        }else{
            const updateMessage = {...lastMessage, message:lastMessage?.message + data};
            updateLocalStorage({...updateMessage, editMode: true});
            setChatHistory((prevChatHistory) => {
                const updatedChatHistory = [...prevChatHistory];      
                updatedChatHistory[updatedChatHistory.length - 1] = updateMessage;
                return updatedChatHistory;
              });
 
        }
    }

    return (
        <ChatHistoryContext.Provider value={{ 
            chatHistory,
            setChatHistory,
            addMessageToHistory,
            addChatResponseToHistory
            }}>
            {children}
        </ChatHistoryContext.Provider>
    );
};

export const useChatHistory = () => {
    const context = useContext(ChatHistoryContext);
    if(!context){
        throw new Error('useChatHistory must be used within an ChatHistoryProvider');
    }
    return context;
}