import { Chat } from "../models/chat";
import { MessageInterface } from "../models/message";

interface UpdateLocalStorageInterface extends MessageInterface{
    editMode?: boolean;
}

export const updateLocalStorage = ({message, role, editMode}: UpdateLocalStorageInterface) => {

    const storedChatHistory = localStorage.getItem('druralChatHistory');
    if(storedChatHistory){

      const parsedChatHistory = JSON.parse(storedChatHistory);
      let newChatHistory: Chat | null = null;
      const messages = parsedChatHistory.history;

      if(messages){

        if(editMode){
            const updatedHistory = messages;
            updatedHistory[updatedHistory.length -1] = {role: role, message: message}
            newChatHistory = {...parsedChatHistory, history:updatedHistory};
        }else{
            newChatHistory  = {...parsedChatHistory, history: [...parsedChatHistory?.history, {role: role, message: message}]}
        }

      }else{
        newChatHistory = {...parsedChatHistory, history: [{role: role, message: message}]}
        }

        localStorage.setItem('druralChatHistory', JSON.stringify(newChatHistory))
  }
}