import { createContext, ReactNode, useContext, useState } from 'react';

interface WcagInterface{
    increaseFontSize: () => void;
    decreaseFontSize: () => void;
    isHighContrast?: boolean;
    setContrastMode?: () => void;
}

interface WcagProviderInterface{
    children: ReactNode;
}

const defaultValue: WcagInterface = {
    increaseFontSize: () => {},
    decreaseFontSize: () => {},
    isHighContrast: false,
    setContrastMode: () => {},
};

const WcagContext = createContext(defaultValue);


export const WcagProvider = ({ children}: WcagProviderInterface) => {
    const [isHighContrast, setIsHighContrast] = useState<boolean>(false);
    const [multiplier, setMultiplier] = useState(1);

    const increaseFontSize = () => {
        const newMultiplier = multiplier + 0.1;
        setMultiplier(newMultiplier);
        document.documentElement.style.setProperty('--font-multiplier', (newMultiplier).toString());
    };

    const decreaseFontSize = () => {
        const newMultiplier = Math.max(multiplier - 0.1, 0.5);
        setMultiplier(newMultiplier);
        document.documentElement.style.setProperty('--font-multiplier', (newMultiplier).toString());
    };

    const setContrastMode = () => {
        setIsHighContrast((prevState)=> !prevState)
    }

    return (
        <WcagContext.Provider value={{ 
            increaseFontSize, 
            decreaseFontSize,
            isHighContrast,
            setContrastMode
            }}>
            {children}
        </WcagContext.Provider>
    );
};

export const useWcag = () => {
    const context = useContext(WcagContext);
    if(!context){
        throw new Error('useWcag must be used within an WcagProvider');
    }
    return context;
}